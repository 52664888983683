import React, { useEffect, useState } from 'react';
import Layout from '@layouts/login';
import SEO from '@layouts/seo';
// import { login, isAuth } from '@utils/feathers-connect';
import { navigate } from 'gatsby';
import { isSSR } from '@utils/helper';
import { Error } from '@components/styledComponents/index';

let doLogin = () => {};

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function signIn() {
    const result = await doLogin ({
      email: username,
      password
    });

    if (result?.user && Object.keys(result.user).length > 0 && result.accessToken) {
      navigate('/dashboard');
    }
    if (result?.error) {
      setErrorMessage(result.error.message);
    }
  }

  useEffect(() => {
    async function loadUser() {
      const { isAuth, login } = await import('@utils/feathers-connect');
      doLogin = login;
      const user = await isAuth();
      if (user) {
        navigate('/dashboard');
      }
    }
    if (!isSSR) {
      loadUser();
    }
  }, []);

  return (
    <Layout>
      <SEO title="Login" />
      <div>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        <img src={require('@images/logo.png')} alt="" />
                      </div>
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center">
                            <h4>تسجيل الدخول</h4>
                            {errorMessage && <Error>{errorMessage == 'Invalid login' ? 'معلومات الدخول خاطئة!' : errorMessage}</Error>}
                          </div>
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                اسم المستخدم
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">
                                كلمة المرور
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                required
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                              />
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={signIn}
                              >
                                دخول
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
