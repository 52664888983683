import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '@styles/nunito.scss';
import '@styles/poppins.scss';
import '@styles/workSans.scss';
import '@styles/app.scss';


const LoginLayout = ({ children }) => {
  useEffect(() => {
    document.body.setAttribute('main-theme-layout', 'rtl');
    document.documentElement.dir = 'rtl';
  }, []);

  return (
    <main>{children}</main>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
